import AvatarSet from './assets/images/avatar-set.png'

export default function Home() {
  return (
    <div className='avatar'>
      <div className='container'>
        <h2 style={{ textAlign: 'center' }}>Sign up today and start your journey to a distraction-free life!</h2>
        <div className='first-section'>
          <img src={AvatarSet} alt='avatars' />
        </div>
        <button onClick={() => window.open('https://chrome.google.com/webstore/detail/focus-partner/abnbdcdhjjabdcfkdaopenabfllapbmo')} className='section-section'>
          Install Our Extension
        </button>
      </div>
    </div>
  )
}
