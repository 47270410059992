import AvatarCard from './assets/images/avatar-card.png'

export default function Home() {
  return (
    <div className='home'>
      <div className='container'>
        <div className='first-section'>
          <h2>Block distracting websites and Maximize Productivity with Focus Partner</h2>
          <p>In today's digital age, distractions lurk around every corner, threatening to derail your productivity and shatter your focus. Introducing Focus Partner, your steadfast ally in the battle against distractions.
            With Focus Partner, you'll bid farewell to the temptation of social media, the allure of online shopping, and the endless vortex of news headlines.
          </p>
          <p>“Become the architect of your digital environment.”
            Nir eyal</p>
          <button onClick={() => window.open('https://chrome.google.com/webstore/detail/focus-partner/abnbdcdhjjabdcfkdaopenabfllapbmo')}>
            Install Our Extension
          </button>
        </div>
        <div className='second-section'>
          <img src={AvatarCard} alt='avatars' />
        </div>
      </div>
    </div>
  )
}
