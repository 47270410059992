export default function Header({ resultRef }) {
  const scrollToContact = () => {
    resultRef.current.scrollIntoView({ behavior: "smooth" })
  }
  return (
    <div className="header">
      <div className="container">
        <div
          style={{
            fontWeight: '600',
            fontSize: '35px',
            textAlign: 'center',
            letterSpacing: '1px'
          }}>
          <span style={{
            color: '#1a6db4'
          }}>Focus</span>
          <span style={{
            color: '#28aa99',
            marginLeft: '8px'
          }}>Partner</span>
        </div>
        <div className="menu" style={{
          gap: '13px',
        }}>
          <p>Blog</p>
          <p style={{
            border: 'solid 1px #bfccca',
          }}></p>
          <p style={{
            cursor: 'pointer'
          }} onClick={() => scrollToContact()}>Contact us</p>
        </div>
      </div>

    </div>
  )
}
