import { useEffect } from 'react'
import { useState } from 'react'
import AddName from './assets/images/add-name.png'
import CreatePartner from './assets/images/create-partner.png'
import Timer from './assets/images/timer.png'

const steps = [{
  value: 'Add your name',
  image: AddName
}, {
  value: 'Create your focus partner',
  image: CreatePartner
}, {
  value: 'Begin your journey with our focus timer',
  image: Timer
}]

let intervalId = 0;
export default function Flow() {
  const [activeStep, setActiveStep] = useState(0)

  const changeStep = (index) => {
    setActiveStep(index)
    clearInterval(intervalId);
  }

  const timer = () => {
    if (!intervalId) {
      intervalId = setInterval(() => {
        setActiveStep(prev => {
          if (prev === 2)
            return 0
          else
            return prev + 1
        })
      }, 5000);
    }
  }

  useEffect(() => {
    timer();
  }, [])

  return (
    <div className='flow'>
      <div className='container'>
        <div className='first-section'>
          <img src={steps[activeStep].image} alt={steps[activeStep].value} />
        </div>
        <div className='second-section'>
          <h2>Simple, Effective and Empowering</h2>
          {
            steps.map((step, index) => (
              <div className='step-container' onClick={() => changeStep(index)}>
                <span className={activeStep === index ? 'first-section first-active' : 'first-section first-in-active'}>
                  {index + 1}
                </span>
                <span className={activeStep === index ? 'second-section second-active' : 'second-section second-in-active'}>
                  {step.value}
                </span>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )

}
