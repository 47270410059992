import {useRef} from 'react';
import Header from "./header";
import Home from "./home";
import Flow from "./flow";
import Avatar from "./avatar";
import Contact from "./contact";
import "./App.scss";

function App() {
  const resultRef = useRef(null);

  return (
    <div>
      <Header  resultRef={resultRef}/>
      <Home />
      <Flow />
      <Avatar />
      <Contact ref={resultRef}/>
    </div>
  );
}

export default App;
