import { useState, forwardRef } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config'
import Instagram from './assets/images/instagram1.png'

const Home = forwardRef((props, ref) => {
  const [contactData, setContactData] = useState({});
  const [disable, setDisable] = useState(false)

  const onSubmit = () => {
    if (contactData.name && contactData.email && contactData.mobile && contactData.description) {
      setDisable(true)
      axios.post(`${API_BASE_URL}createenquiry`, contactData).then((response) => {
        setDisable(false)
        setContactData({});
        if (response.status === 200) {
          alert('Submitted Successfully!');
        }
      }).catch(err => {
        setDisable(false)
        alert('Something went wrong!');
      })
    }
  }

  return (
    <div className="contact" ref={ref}>
      <div className="container">
        <div>
          <h2>Contact Us</h2>
          <br />
          <p>You can reach us anytime via <span style={{
            color: '#28aa99'
          }}>focuspartner@gmail.com</span></p>
          <div className="card">
            <div className="first-section">
              <div>
                <p>Your Name *</p>
                <input value={contactData.name || ''} onChange={(e) => setContactData({ ...contactData, name: e.target.value })} />
              </div>
              <div>
                <p>Email Address *</p>
                <input value={contactData.email || ''} onChange={(e) => setContactData({ ...contactData, email: e.target.value })} />
              </div>
            </div>
            <div className="second-section">
              <div>
                <p>Mobile number *</p>
                <input value={contactData.mobile || ''} onChange={(e) => setContactData({ ...contactData, mobile: e.target.value })} />
              </div>
              <div>
                <p>How can we help? *</p>
                <input value={contactData.description || ''} onChange={(e) => setContactData({ ...contactData, description: e.target.value })} />
              </div>
            </div>
            <button style={{
              cursor: disable ? 'not-allowed' : 'pointer'
            }} disabled={disable} onClick={onSubmit} className="button">
              Submit
            </button>
          </div>
          <p className="copyright">© 2023 focus partner. All rights reserved</p>
          <div style={{
            display: 'flex',
            gap: '10px',
            justifyContent: 'flex-end',
            marginTop: '2%'
          }}>
            <p style={{
              fontWeight: '100'
            }}>Follow us on</p>
            <a href="https://www.instagram.com/myfocuspartner/" target='_blank' rel="noreferrer"><img src={Instagram}  alt='instagram'/></a>
          </div>
        </div>
      </div>
    </div>
  )
});

export default Home;